* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-fluid {
  padding: 0;
}

body {
  margin: 0;
  color: #455560;
  font-size: 18px;
  font-family: 'Roboto',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #efefef;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
}

.app-layout {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.dashboard--box-title {
  font-size: 20px;
  font-weight: 500;
}

.dashboard--box-text {
  font-size: 42px;
  margin-top: 13px;
}

.btn-borderless {
  border: 0 !important;
}